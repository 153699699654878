import classes from "./About.module.css";
import Fade from "react-reveal/Fade";

import Container from "../UI/Container";

const About = () => {
  return (
    <Container className={classes.container}>
      <div>
        <Fade bottom cascade>
          <h1 className={classes.title}>GAME & WEB DEV </h1>{" "}
          <h3 className={classes["small-title"]}>and pixel art designer.</h3>
        </Fade>
        <Fade bottom>
          <p className={classes.text}>
            Hello, I'm{" "}
            <span className={classes["colored-word"]}>Med Amsakine</span>, a
            versatile creator with a passion for gaming and technology. With{" "}
            <strong>5 years of experience</strong> in video game development, I
            specialize in crafting engaging 2D and 3D games. Additionally, I
            share my knowledge on YouTube, where I create educational videos
            that guide aspiring game developers through the exciting world of
            game development. Alongside my game development journey, I also
            bring more than{" "}
            <strong>3 years of web development expertise</strong> to the table.
            Welcome to my portfolio!
          </p>
        </Fade>
      </div>
    </Container>
  );
};

export default About;
