import DevMarketPlace from "../assets/imgs/sites/DevMarketPlace.png";
import ExpensesProject from "../assets/imgs/sites/ExpensesProject.png";
import MeteoApp from "../assets/imgs/sites/MeteoApp.png";
import ToDoList from "../assets/imgs/sites/ToDoList.png";

const sitesList = [
  {
    url: "https://www.youtube.com/watch?v=Di7LvmMe_Bg",
    img: DevMarketPlace,
    title: "DevMarketPlace",
    date: "2023",
    state: "",
    github: "true",
  },
  {
    url: "https://github.com/MohamedAmsakine17/Expenses_manager_React",
    img: ExpensesProject,
    title: "Expenses manager",
    date: "2023",
    state: "",
    github: "true",
  },
  {
    url: "https://github.com/MohamedAmsakine17/ToDoList_EJS",
    img: ToDoList,
    title: "To Do List",
    date: "2023",
    state: "",
    github: "true",
  },
  {
    url: "https://github.com/MohamedAmsakine17/meteo_api",
    img: MeteoApp,
    title: "Weather Application",
    date: "2023",
    state: "Under Development",
    github: "false",
  },
];

export default sitesList;
