import Rabbit from "../assets/imgs/arts/Rabbit.png";
import House from "../assets/imgs/arts/House.png";
import GameMap from "../assets/imgs/arts/GameMap.png";
import Boss from "../assets/imgs/arts/Boss.gif";
import japanHouse from "../assets/imgs/arts/JapanHouse.png";
import Jefe from "../assets/imgs/arts/Jefe.png";
import MushuBanner from "../assets/imgs/arts/MushuMonstersTwitterBanner.png";
import ShootGame from "../assets/imgs/arts/ShootGame.png";
import CarRacing from "../assets/imgs/arts/CarRacing.png";
import DopPerson from "../assets/imgs/arts/DopPersone.png";
import Monsters from "../assets/imgs/arts/Monsters.png";
import Sincra from "../assets/imgs/arts/Sincra.png";
import Jotaro from "../assets/imgs/arts/Jotaro.png";
import Majima from "../assets/imgs/arts/Majima.png";
import Yakuzas from "../assets/imgs/arts/Yakuzas.gif";
import Dragon from "../assets/imgs/arts/Dragon.png";
import Doctor from "../assets/imgs/arts/Doctor.png";
import Computer from "../assets/imgs/arts/Computer.gif";

const artsList = [
  {
    img: Rabbit,
    size: "medium",
    col: 1,
  },
  {
    img: House,
    size: "medium",
    col: 2,
  },
  {
    img: GameMap,
    size: "big",
    col: 3,
  },
  {
    img: Boss,
    size: "medium",
    col: 1,
  },
  {
    img: Jefe,
    size: "small",
    col: 2,
  },
  {
    img: japanHouse,
    size: "medium",
    col: 2,
  },
  {
    img: MushuBanner,
    size: "small",
    col: 3,
  },
  {
    img: ShootGame,
    size: "medium",
    col: 1,
  },
  {
    img: CarRacing,
    size: "big",
    col: 2,
  },
  {
    img: DopPerson,
    size: "medium",
    col: 3,
  },
  {
    img: Monsters,
    size: "small",
    col: 1,
  },
  {
    img: Sincra,
    size: "medium",
    col: 2,
  },
  {
    img: Jotaro,
    size: "medium",
    col: 3,
  },
  {
    img: Majima,
    size: "medium",
    col: 3,
  },
  {
    img: Doctor,
    size: "medium",
    col: 1,
  },
  {
    img: Yakuzas,
    size: "medium",
    col: 2,
  },
  {
    img: Dragon,
    size: "medium",
    col: 3,
  },
  {
    img: Computer,
    size: "big",
    col: 1,
  },
];

export default artsList;
