import classes from "./Asset.module.css";
import Fade from "react-reveal/Fade";

const Asset = (props) => {
  return (
    <div key={props.key} className="col-lg-4 col-md-12 col-12">
      <Fade bottom>
        <a
          href={props.link}
          target="_blank"
          rel="noreferrer"
          className={classes.asset}
          style={{ backgroundColor: props.bg }}
        >
          <div className={classes["img-container"]}>
            <div
              style={{
                backgroundImage: `url(${props.img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
              }}
            ></div>
          </div>
          <div className={classes["asset-info"]}>
            <p className={classes["asset-title"]}>{props.title}</p>
            <small className={classes["asset-type"]}>{props.type}</small>
          </div>
        </a>
      </Fade>
    </div>
  );
};

export default Asset;
