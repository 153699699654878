import classes from "./Site.module.css";
import Fade from "react-reveal/Fade";

const Site = (props) => {
  return (
    <div key={props.key} className="col-lg-4 col-md-6 col-12">
      <Fade bottom>
        <a
          className={classes.site}
          href={props.url}
          target="_blank"
          rel="noreferrer"
        >
          <div className={classes["img-container"]}>
            <div
              style={{
                backgroundImage: `url(${props.img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
              }}
            ></div>
          </div>
          <p className={classes["site-title"]}>{props.title}</p>
          <small className={classes["site-date"]}>{props.date}</small>
          <div className={classes["site-info"]}>
            <div className={classes["site-platform"]}>
              {props.github === "true" && <i class="bi bi-github"></i>}
            </div>
            <div className={classes["site-state"]}>{props.state}</div>
          </div>
        </a>
      </Fade>
    </div>
  );
};

export default Site;
