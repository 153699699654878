import { useState } from "react";
import Fade from "react-reveal/Fade";

import classes from "./Projects.module.css";

import Container from "../UI/Container";

import Games from "./Games";
import Sites from "./Sites";
import Arts from "./Arts";

const Projects = () => {
  const [type, SetType] = useState("games");

  const OnClickHandler = (type) => {
    SetType(type);
  };

  return (
    <Container className={classes.container}>
      <Fade bottom cascade>
        <h2 className={classes.title}>Projects</h2>
        <div className={classes.buttons}>
          <button
            id="games"
            onClick={() => {
              OnClickHandler("games");
            }}
            style={{ color: type === "games" ? "#f79617" : "#625565" }}
          >
            Games
          </button>
          <button
            id="websites"
            onClick={() => {
              OnClickHandler("websites");
            }}
            style={{ color: type === "websites" ? "#f79617" : "#625565" }}
          >
            WebSites
          </button>
          <button
            id="arts"
            onClick={() => {
              OnClickHandler("arts");
            }}
            style={{ color: type === "arts" ? "#f79617" : "#625565" }}
          >
            Art
          </button>
        </div>
        <hr className={classes.hr} />
      </Fade>
      {type === "games" && <Games />}
      {type === "websites" && <Sites />}
      {type === "arts" && <Arts />}
    </Container>
  );
};

export default Projects;
