import classes from "./Course.module.css";
import Fade from "react-reveal/Fade";

const Course = (props) => {
  return (
    <div key={props.key} className="col-lg-4 col-md-12 col-12">
      <Fade bottom>
        <a
          href={props.link}
          target="_blank"
          rel="noreferrer"
          className={classes.course}
        >
          <div className={classes["img-container"]}>
            <div
              style={{
                backgroundImage: `url(${props.img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
              }}
            ></div>
          </div>
          <div className={classes["course-info"]}>
            <p className={classes["course-title"]}>{props.title}</p>
            <small className={classes["course-lastUpdate"]}>
              Last Update : {props.lastUpdate}
            </small>
            <small className={classes["course-langauge"]}>
              Language :{props.language}
            </small>
          </div>
        </a>
      </Fade>
    </div>
  );
};

export default Course;
