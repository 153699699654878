import classes from "./Footer.module.css";

import Container from "../UI/Container";

const Footer = () => {
  return (
    <Container>
      <footer className={classes.footer}>
        <div>Copyright © Glimmer 2023 · All rights reserved</div>
        <div>
          <i class="bi bi-envelope-at-fill"></i> GlimmerArtisan@gmail.com
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
