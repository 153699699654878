import classes from "./Game.module.css";
import Fade from "react-reveal/Fade";

const Game = (props) => {
  return (
    <div key={props.key} className="col-lg-4 col-md-6 col-12">
      <Fade bottom>
        <a
          className={classes.game}
          href={props.url}
          target="_blank"
          rel="noreferrer"
        >
          <div className={classes["img-container"]}>
            <div
              style={{
                backgroundImage: `url(${props.img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
              }}
            ></div>
          </div>
          <p className={classes["game-title"]}>{props.title}</p>
          <small className={classes["game-date"]}>{props.date}</small>
          <div className={classes["game-info"]}>
            <div className={classes["game-platform"]}>
              {props.googlePlay === "true" && (
                <i class="bi bi-google-play me-2"></i>
              )}
              {props.appStore === "true" && <i class="bi bi-apple me-2"></i>}
              {props.itch === "true" && (
                <i class="bi bi-browser-chrome me-2"></i>
              )}
              {props.github === "true" && <i class="bi bi-github"></i>}
            </div>
            <div className={classes["game-state"]}>{props.state}</div>
          </div>
        </a>
      </Fade>
    </div>
  );
};

export default Game;
