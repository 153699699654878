import classes from "./Assets.module.css";
import Fade from "react-reveal/Fade";

import Container from "../UI/Container";

import Asset from "./asset/Asset";

import RedText from "../../assets/imgs/assets/Red Font.png";

const assetsList = [
  {
    title: "Red Pixel Font",
    type: "Font",
    img: RedText,
    backgroundColor: "#C32454",
    link: "https://glimmerartisan.itch.io/redfont",
  },
];

const Assets = () => {
  return (
    <Container className={classes.container}>
      <Fade bottom cascade>
        <h2 className={classes.title}>Assets</h2>
      </Fade>
      <div className="row">
        {assetsList.map((asset, index) => {
          return (
            <Asset
              key={index}
              title={asset.title}
              type={asset.type}
              img={asset.img}
              bg={asset.backgroundColor}
              link={asset.link}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default Assets;
