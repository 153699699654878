//import classes from "./Games.module.css";

import Game from "./games/Game";

import gamesList from "../../data/Games";

const Games = (props) => {
  return (
    <div>
      <div className="row">
        {gamesList.map((game, index) => {
          return (
            <Game
              key={index}
              url={game.url}
              img={game.img}
              title={game.title}
              date={game.date}
              googlePlay={game.gp}
              appStore={game.as}
              itch={game.br}
              state={game.state}
              github={game.github}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Games;
