import classes from "./Arts.module.css";

import Art from "./arts/Art";

import artsList from "./../../data/Arts";

const Arts = () => {
  return (
    <div className={classes.arts}>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          {artsList.map((art, index) => {
            return (
              art.col === 1 && <Art key={index} size={art.size} img={art.img} />
            );
          })}
        </div>
        <div className="col-lg-4 col-md-6">
          {artsList.map((art, index) => {
            return (
              art.col === 2 && <Art key={index} size={art.size} img={art.img} />
            );
          })}
        </div>
        <div className="col-lg-4 ">
          {artsList.map((art, index) => {
            return (
              art.col === 3 && <Art key={index} size={art.size} img={art.img} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Arts;
