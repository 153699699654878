import classes from "./Header.module.css";
import Fade from "react-reveal/Fade";

import GlimmerLogo from "../../assets/imgs/Glimmer Logo.png";

import Container from "../UI/Container";

import HeaderIcons from "./Header-Icons";
import About from "../about/About";

const Header = () => {
  return (
    <Container className={classes.container}>
      <Fade bottom>
        <img
          className={classes.img}
          src={GlimmerLogo}
          alt="Glimmer Pixel art Logo"
        />
        <HeaderIcons />
      </Fade>
      <About />
    </Container>
  );
};

export default Header;
