import classes from "./Courses.module.css";
import Fade from "react-reveal/Fade";

import Container from "../UI/Container";

import Course from "./course/Course";

import PhotonNetwork from "../../assets/imgs/courses/PhotonNetworkUnity.jpg";

const coursesList = [
  {
    img: PhotonNetwork,
    title: "Make multiplayer game using Unity Engine & Photon",
    lastUpdate: "2021",
    Language: "Arabic",
    link: "https://www.udemy.com/course/unity-photon-network/",
  },
];

const Courses = () => {
  return (
    <Container className={classes.container}>
      <Fade bottom cascade>
        <h2 className={classes.title}>Courses</h2>
      </Fade>
      <div className="row">
        {coursesList.map((course, index) => {
          return (
            <Course
              key={index}
              img={course.img}
              title={course.title}
              lastUpdate={course.lastUpdate}
              language={course.Language}
              link={course.link}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default Courses;
