//import classes from "./Sites.module.css";

import Site from "./sites/Site";

import sitesList from "../../data/Sites";

const Sites = () => {
  return (
    <div>
      <div className="row">
        {sitesList.map((site, index) => {
          return (
            <Site
              key={index}
              url={site.url}
              img={site.img}
              title={site.title}
              date={site.date}
              github={site.github}
              state={site.state}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Sites;
