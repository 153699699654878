import PlaneJoyride from "../assets/imgs/games/PlaneJoyride.png";
import TheBomber from "../assets/imgs/games/TheBomber.png";
import ChickenOut from "../assets/imgs/games/ChickenOut.png";
import YakuzaDash from "../assets/imgs/games/YakuzaDash.png";
import SpaceY from "../assets/imgs/games/SpaceY.png";
import Tilevania from "../assets/imgs/games/TileVania.png";
import Mushu from "../assets/imgs/games/Mushu.png";
import DrumKit from "../assets/imgs/games/DrumKit.png";
import SimonGame from "../assets/imgs/games/SimonGame.png";
import DiceeGame from "../assets/imgs/games/DiceeGame.png";

const gamesList = [
  {
    url: "https://apkpure.com/plane-joyride/com.SweekGames.PlaneJoyride",
    img: PlaneJoyride,
    title: "Plane Joyride",
    date: "2019-2020",
    state: "Deleted by Google Play",
    gp: "true",
    as: "false",
    br: "false",
    github: "flase",
  },
  {
    url: "https://www.instagram.com/p/CpX3rAULEeM/",
    img: TheBomber,
    title: "The Bomber",
    date: "2020-",
    state: "Incomplete",
    gp: "false",
    as: "false",
    br: "false",
    github: "flase",
  },
  {
    url: "https://abo-7mood.itch.io/chickenout",
    img: ChickenOut,
    title: "ChickenOut",
    date: "2021",
    state: "Game for a competition",
    gp: "false",
    as: "false",
    br: "false",
    github: "flase",
  },
  {
    url: "https://github.com/MohamedAmsakine17/SpaceWars",
    img: SpaceY,
    title: "Space Wars",
    date: "2021",
    state: "",
    gp: "false",
    as: "false",
    br: "false",
    github: "true",
  },
  {
    url: "https://github.com/MohamedAmsakine17/TileVania",
    img: Tilevania,
    title: "Tilevania",
    date: "2021",
    state: "",
    gp: "false",
    as: "false",
    br: "false",
    github: "true",
  },
  {
    url: "https://github.com/MohamedAmsakine17/SimonGame",
    img: SimonGame,
    title: "Simon Game",
    date: "2021",
    state: "",
    gp: "false",
    as: "false",
    br: "false",
    github: "true",
  },
  {
    url: "https://github.com/MohamedAmsakine17/DrumKit",
    img: DrumKit,
    title: "Drum Kit",
    date: "2021",
    state: "",
    gp: "false",
    as: "false",
    br: "false",
    github: "true",
  },
  {
    url: "https://github.com/MohamedAmsakine17/DiceeGame",
    img: DiceeGame,
    title: "Dicee Game",
    date: "2021",
    state: "",
    gp: "false",
    as: "false",
    br: "false",
    github: "true",
  },
  {
    url: "https://play.google.com/store/apps/details?id=com.DefaultCompany.yakuza&hl=en&gl=US",
    img: YakuzaDash,
    title: "Yakuza Dash",
    date: "2021-2022",
    state: "",
    gp: "true",
    as: "true",
    br: "true",
    github: "flase",
  },
  {
    url: "https://twitter.com/MushuMonsterz",
    img: Mushu,
    title: "Mushu Monsters",
    date: "2022-",
    state: "Under Development",
    gp: "false",
    as: "false",
    br: "false",
    github: "flase",
  },
];

export default gamesList;
