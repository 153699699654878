import classes from "./Art.module.css";
import Fade from "react-reveal/Fade";

const Art = (props) => {
  return (
    <div>
      <Fade bottom>
        <div
          key={props.key}
          className={classes.img}
          style={{
            height:
              props.size === "big"
                ? "575px"
                : props.size === "medium"
                ? "350px"
                : "125px",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${props.img})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
            }}
          ></div>
        </div>
      </Fade>
    </div>
  );
};

export default Art;
