import classes from "./Header-Icons.module.css";

const HeaderIcons = () => {
  return (
    <div className={classes.icons}>
      <a
        target="_blank"
        href="https://twitter.com/GlimmerArtisan"
        className={classes.icon}
      >
        <i className="bi bi-twitter-x"></i>
      </a>
      <a
        target="_blank"
        href="https://www.youtube.com/@GlimmerAR"
        className={classes.icon}
      >
        <i className="bi bi-youtube"></i>
      </a>
      <a
        target="_blank"
        href="https://www.linkedin.com/in/me2dev/"
        className={classes.icon}
      >
        <i className="bi bi-linkedin"></i>
      </a>
      <a
        target="_blank"
        href="https://www.instagram.com/med_amsakine/"
        className={classes.icon}
      >
        <i className="bi bi-instagram"></i>
      </a>
    </div>
  );
};

export default HeaderIcons;
